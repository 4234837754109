import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//获取博主积分
export const getBloggerScoreList = (data: any) =>
  request({
    url: "/adm/data/blogger/score/list",
    method: "post",
    data,
  });

//导出博主积分
export const exportBloggerScoreList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/blogger/score/list`,
  });

//获取成员审核列表
export const getUnionApplyList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/union/apply/list",
  });

//修改审核状态
export const setUnionApplyStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/union/apply/status",
  });

//获取信誉分列表
export const getCreditList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/credit/list",
  });

//导出信誉分列表
export const exportCreditList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/supervise/user/credit/list`,
  });

//删除工作室
export const deletUnionManage = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/union/manage/del",
  });

//获取工作室列表
export const getUnionAuthList = () =>
  request({
    method: "post",
    url: "/adm/user/union/auth/list",
  });

//获取博主等级配置
export const getBloggerLevelConf = () =>
  request({
    method: "post",
    url: "/adm/user/blogger/level/conf",
  });

//获取工作室管理列表
export const getUnionManagerList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/union/manager/list",
  });
